/**
 * Combines a date and a time into a single ISO string
 * @param {String} date in ISO format
 * @param {String} time in HH:mm format
 * @return {String} ISO string
 */
const combineDateTime = function (date, time) {
    // Convert date to a Date object
    const dateObject = new Date(date);

    // Extract hours and minutes from time
    const [hours, minutes] = time.split(':').map(Number);

    // Set the hours and minutes
    dateObject.setHours(hours, minutes, 0, 0);

    // Convert back to ISO format
    return dateObject.toISOString();
};

module.exports = { combineDateTime };
