/** Function to get the color of the speed dot based on the speed value
 * @param {number} speed - The speed value in km/h
 * @return {string} The color of the speed dot in hex format
 */
function getSpeedDotColor(speed) {
    const speedColors = ['#3D9CE6', '#00AC41', '#DEF209', '#FFF10E', '#FAB007', '#EA3203', '#B02A08'];
    const speedLimits = [5, 30, 40, 50, 90, 110];

    // Get colors based on speed limits
    let colorIndex = speedLimits.findIndex((limit) => speed <= limit);

    // If speed is greater than the highest limit, set to the last color
    if (colorIndex === -1) {
        colorIndex = speedColors.length - 1;
    }

    return speedColors[colorIndex];
}

module.exports = { getSpeedDotColor };
