/**
 * Validate if the coordinates are valid latitude and longitude
 * @param {Number} latitude
 * @param {Number} longitude
 * @return {Boolean}
 */
const validateCoordinates = (latitude, longitude) => {
    if (typeof latitude !== 'number' || typeof longitude !== 'number') {
        return false;
    }

    if (latitude < -90 || latitude > 90) {
        return false;
    }
    if (longitude < -180 || longitude > 180) {
        return false;
    }
    return true;
};

module.exports = { validateCoordinates };
