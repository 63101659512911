angular.module('mTransportApp').controller('ClientsController', [
    '$scope',
    '$rootScope',
    '$q',
    '$translate',
    '$timeout',
    '$controller',
    'sortingTools',
    '$location',
    function ($scope, $rootScope, $q, $translate, $timeout, $controller, sortingTools, $location) {
        angular.extend(this, $controller('ToolsController', { $scope: $scope }));

        /* =======================================================================================================================
       ALL CLIENTS
    =======================================================================================================================  */

        /* INITIALISATION */
        $scope.initializeClients = function () {
            $scope.loading = true;
            $scope.clients = [];
            // Initialize favorites toggle
            $scope.filterOptions = {
                showOnlyFavourites: false,
            };
            $scope.buttonsGroupSelectedOption = 0;
            initializeFavoritesToggleText();
            $timeout(function () {
                initializeFavoritesToggleText();
            }, 0);

            $scope.requestAdminGetAllClients().then(
                function (answer) {
                    $scope.clients = sortingTools.sortClients(answer.data.clients);

                    $scope.clients.map((client) => {
                        client.tripCount = calculateTripCountForNext4Days(client);

                        // For sorting
                        if (!client.fullName) {
                            client.fullName = '';
                        }
                    });

                    $scope.updateClientsForFavourite();
                    $scope.loading = false;
                    $scope.csvIsLoading = false;

                    $scope.searchValue = {
                        value: '',
                    };

                    $scope.orderType = {
                        orderBy: 'name',
                        ascending: true,
                    };

                    $scope.allClientsSave = $scope.clients;

                    if ($scope.clients.some((client) => client.isFavourite === true)) {
                        $scope.setFavouriteFiltering(true);
                        $scope.buttonsGroupSelectedOption = 1;
                    }
                },
                function (error) {
                    $scope.catchErrorDefault(error);
                }
            );
        };

        /**
         * Returns the total of a field for all clients or the filtered clients
         * @param {string} fieldPath
         * @return {number} the total
         */
        $scope.getTotal = function (fieldPath) {
            return $scope.clients.reduce((total, client) => {
                const value = getValueByPath(client, fieldPath);
                return total + parseFloat(value);
            }, 0);
        };

        /**
         * Returns the value by path
         * @param {Object} obj
         * @param {string} path
         * @return {number} the value of the field
         */
        const getValueByPath = (obj, path) => {
            const parts = path.split('.');
            let value = obj;

            for (const part of parts) {
                if (value[part] == null) {
                    return 0; // Handle undefined values
                }
                value = value[part];
            }

            return value;
        };

        /**
         * Count the number of trips for the next 4 days
         * @param {Object} client
         * @return {Object} an object containing the number of trips for the next 4 days
         **/
        function calculateTripCountForNext4Days(client) {
            const nextDays = 4;
            const tripCount = {
                today: 0,
                tomorrow: 0,
                in2Days: 0,
                in3Days: 0,
                in4Days: 0,
            };

            for (let i = 0; i <= nextDays; ++i) {
                const targetDate = moment().add(i, 'days').format('YYYY-MM-DD');
                const trip = client.trips.find((trip) => trip.date === targetDate);
                const fieldName = Object.keys(tripCount)[i];
                tripCount[fieldName] = trip ? trip.tripCount : 0;
            }

            return tripCount;
        }

        /* =======================================================================================================================
       ROUTES SETTINGS
    =======================================================================================================================  */

        $scope.initializeClientsRoutesSettings = function () {
            $scope.loading = true;
            // Initialize favorites toggle
            $scope.filterOptions = {
                showOnlyFavourites: false,
            };
            $scope.buttonsGroupSelectedOption = 0;
            initializeFavoritesToggleText();
            $timeout(function () {
                initializeFavoritesToggleText();
            }, 0);

            $scope.requestAdminGetAllClients(true, false).then(
                function (answer) {
                    $scope.clients = sortingTools.sortClients(answer.data.clients);

                    $scope.clients.map((client) => {
                        // For sorting
                        if (!client.fullName) {
                            client.fullName = '';
                        }
                        client.preferencesSummary = { ...client.routes.preferencesSummary };
                    });

                    $scope.updateClientsForFavourite();
                    $scope.loading = false;
                    $scope.csvIsLoading = false;

                    $scope.searchValue = {
                        value: '',
                    };

                    $scope.orderType = {
                        orderBy: 'name',
                        ascending: true,
                    };

                    $scope.allClientsSave = $scope.clients;

                    if ($scope.clients.some((client) => client.isFavourite === true)) {
                        $scope.setFavouriteFiltering(true);
                        $scope.buttonsGroupSelectedOption = 1;
                    }
                },
                function (error) {
                    $scope.catchErrorDefault(error);
                }
            );
        };

        /* =======================================================================================================================
       OPERATIONS STATISTICS 
    =======================================================================================================================  */

        /**
         * Initializes the operations statistics page
         */
        $scope.initializeClientsOperationsStatistics = function () {
            $scope.selectedDate = moment().subtract(1, 'days'); // Date is by default the day before today

            // Initialize date picker config
            $scope.minDate = moment().set({
                year: 2017,
                month: 8,
                date: 18,
                hour: 23,
                minutes: 59,
                second: 59,
            });

            $scope.datePickerConfig = {
                isEnabled: true,
                startDate: $scope.selectedDate.format('YYYY-MM-DD'),
                minDate: $scope.minDate.format('YYYY-MM-DD'),
                onDatePickerChange: $scope.onDatePickerChange,
            };

            // Initialize sorting
            $scope.orderType = {
                orderBy: 'name',
                ascending: true,
            };

            // Initialize favourites
            $scope.filterOptions = {
                showOnlyFavourites: false,
            };
            $scope.buttonsGroupSelectedOption = 0;
            initializeFavoritesToggleText();

            // Initialize search
            $scope.searchValue = {
                value: '',
            };

            // Initialize report
            $scope.fetchReportClientsTrips($scope.selectedDate);
        };

        /**
         * Fetch the report for the selected date
         * @param {Object} date
         */
        $scope.fetchReportClientsTrips = function (date) {
            $scope.loading = true;
            $scope.clients = [];
            const dateYYYYMMDD = moment(date).format('YYYY-MM-DD');

            $scope.requestReportClientsTrips(dateYYYYMMDD).then(
                function (answer) {
                    $scope.clients = sortingTools.sortClients(answer.clients);

                    // Update clients for sorting
                    $scope.clients.map((client) => {
                        if (!client.fullName) {
                            client.fullName = '';
                        }
                        client.tripsCounts = { ...client.tripsCounts, completedPercentage: getCompletedPercentage(client.tripsCounts) };
                    });

                    $scope.updateClientsForFavourite();

                    $scope.allClientsSave = $scope.clients;

                    const hasFavourites = $scope.clients.some((client) => client.isFavourite === true);
                    if ($scope.filterOptions.showOnlyFavourites && hasFavourites) {
                        // Display only favourites
                        $scope.buttonsGroupSelectedOption = 1;
                    }

                    $scope.applyFilters();

                    $scope.loading = false;
                    $scope.$apply();
                },
                function (error) {
                    $scope.catchErrorDefault(error);
                }
            );
        };

        /**
         * Returns the percentage of completed trips for a client
         * @param {Object} tripsCounts
         * @param {number} tripsCounts.total
         * @param {number} tripsCounts.completed
         * @return {number} the percentage of completed trips
         */
        const getCompletedPercentage = function (tripsCounts) {
            const { completed, total } = tripsCounts;
            return total === 0 ? 0 : Math.round((completed / total) * 100);
        };

        /**
         * Get the percentage of completed trips of all clients
         * @return {number} the percentage of completed trips of all clients
         */
        $scope.getTotalCompletedPercentage = function () {
            const totalCompletedTrips = $scope.getTotal('tripsCounts.completed');
            const totalTrips = $scope.getTotal('tripsCounts.total');
            return totalTrips === 0 ? 0 : Math.round((totalCompletedTrips / totalTrips) * 100);
        };

        /**
         * When the user changes the date in the date picker,
         * update the selected date and fetch the report for that new date
         * @param {Object} newSelectedDate
         */
        $scope.onDatePickerChange = function (newSelectedDate) {
            $scope.loading = true;
            $scope.clients = [];
            $scope.selectedDate = moment(newSelectedDate);
            $scope.$apply();
            $scope.fetchReportClientsTrips(newSelectedDate);
        };

        /**
         * When the user presses the previous, next or today button in the date picker,
         * update the selected date and fetch the report for that new date
         * @param {'prev' | 'next' | 'today'} direction
         */
        $scope.onDateButtonPress = function (direction) {
            let newSelectedDate;

            switch (direction) {
                case 'prev':
                    newSelectedDate = moment($scope.selectedDate).subtract(1, 'days');
                    break;
                case 'next':
                    newSelectedDate = moment($scope.selectedDate).add(1, 'days');
                    break;
                case 'today':
                    newSelectedDate = moment();
                    break;
            }

            // Update the date picker
            $('#panel-header__calendar-button').data('daterangepicker').setStartDate(newSelectedDate.format('YYYY-MM-DD'));
            $('#panel-header__calendar-button').data('daterangepicker').setEndDate(newSelectedDate.format('YYYY-MM-DD'));

            $scope.selectedDate = newSelectedDate;
            $scope.fetchReportClientsTrips(newSelectedDate);
        };

        /**
         * Get the displayed date in readable format
         * @return {string} the displayed date
         * @example 'Monday January 20, 2025'
         */
        $scope.getDisplayedStringDate = function () {
            return $scope.convertToReadableDate($scope.selectedDate, 'long');
        };

        /**
         * Check if the selected date is today
         * @return {boolean} true if the selected date is today, false otherwise
         */
        $scope.checkIsSelectedDateToday = function () {
            return $scope.selectedDate.isSame(moment(), 'day');
        };

        /**
         * Check if the selected date is the minimum date allowed
         * @return {boolean} true if the selected date is the minimum date allowed, false otherwise
         */
        $scope.checkIsSelectedDateMinDate = function () {
            return $scope.selectedDate.isSame($scope.minDate, 'day');
        };

        /* =======================================================================================================================
    	FAVORITES, FILTERS AND SEARCH
	=======================================================================================================================  */

        /**
         * Initializes the text in the favorites toggle in the right language
         * Called when fetching clients so that every time the page refreshes, this info does too
         */
        function initializeFavoritesToggleText() {
            $scope.buttonsGroupOptions = [
                {
                    name: $translate.instant('all'),
                    onClick: () => {
                        $scope.setFavouriteFiltering(false);
                    },
                },
                {
                    name: `${$translate.instant('favourites')} <i class="fa fa-star status-yellow"></i>`,
                    onClick: () => {
                        $scope.setFavouriteFiltering(true);
                    },
                },
            ];
        }

        /**
         * Updates the state of the favourite filter (on/off)
         * @param {Boolean} newState
         */
        $scope.setFavouriteFiltering = (newState) => {
            $scope.filterOptions.showOnlyFavourites = newState;
            $scope.applyFilters();
        };

        /**
         * Toggles client into favorite / non-favorite, stores information in local storage
         * @param {Object} client
         */
        $scope.toggleFavourite = function (client) {
            // eslint-disable-next-line no-undef
            const mailHash = sha256($rootScope.loggedUserMail);
            const clientId = client.id;
            // Get favourites for user.
            let favourites = [];
            // eslint-disable-next-line no-undef
            const favouritesRaw = window.localStorage.getItem('favouriteClients-' + mailHash);

            // Validate if current user has favourites.
            if (favouritesRaw) {
                favourites = JSON.parse(favouritesRaw);
            }

            // Search for current client in favourites and add/remove.
            const index = favourites.indexOf(clientId);
            if (index > -1) {
                favourites.splice(index, 1);
            } else {
                favourites.push(clientId);
            }

            // Save favourites.
            // eslint-disable-next-line no-undef
            window.localStorage.setItem('favouriteClients-' + mailHash, JSON.stringify(favourites));

            // Update items.
            $scope.updateClientsForFavourite();
        };

        /**
         * Adds .isFavourite attribute to clients according to data in local storage
         */
        $scope.updateClientsForFavourite = function () {
            const mailHash = sha256($rootScope.loggedUserMail);
            let favourites = [];
            const favouritesRaw = window.localStorage.getItem('favouriteClients-' + mailHash);
            if (favouritesRaw) {
                favourites = JSON.parse(favouritesRaw);
            }

            if ($scope.clients && $scope.clients.length > 0) {
                for (const client of $scope.clients) {
                    client['isFavourite'] = favourites.indexOf(client.id) >= 0;
                }
            }

            $timeout(function () {
                $scope.$apply();
            });
        };

        $scope.applyFilters = function () {
            $scope.clientsToShow = [];
            $scope.clients = $scope.allClientsSave;

            for (const client of $scope.clients) {
                if (passesFilters(client)) {
                    $scope.clientsToShow.push(client);
                }
            }
            $timeout(function () {
                $scope.$apply();
            });
            $scope.clients = $scope.clientsToShow;

            $scope.sortClientsByFields();
        };

        /**
         * Check if the client passes the filters
         * @param {Object} client
         * @return {Boolean} true if client passes filters, false otherwise
         */
        function passesFilters(client) {
            let passes = false;
            const dataToCompare = {
                clientName: client.name,
                clientFullName: client.fullName,
            };

            if (!$scope.filterOptions.showOnlyFavourites || ($scope.filterOptions.showOnlyFavourites && client.isFavourite === true)) {
                if ($scope.containsSearchValue(dataToCompare, $scope.searchValue.value)) {
                    passes = true;
                }
            }

            return passes;
        }

        /**
         * Sort the clients by calling the sortClients function from the sortingTools controller
         * @param {Array} clients the array to be sorted
         * @return {Array} sorted array
         */
        function sortClients(clients) {
            return sortingTools.sortClients(clients, $scope.orderType.orderBy, $scope.orderType.ascending);
        }

        /**
         * Defines which fields the clients array should be sorted by
         * Then sorts a copy of $scope.clients
         * Then loads sorted dispatchers into $scope.clients
         * @param {string} orderBy the fields the array should be sorted by
         */
        $scope.sortClientsByFields = function (orderBy = null) {
            if (orderBy) {
                if ($scope.orderType.orderBy === orderBy) {
                    $scope.orderType = {
                        orderBy: orderBy,
                        ascending: !$scope.orderType.ascending,
                    };
                } else {
                    $scope.orderType = {
                        orderBy: orderBy,
                        ascending: true,
                    };
                }
            }
            const sortedClients = sortClients([...$scope.clients]);
            $scope.clients = sortedClients;
        };

        /* =======================================================================================================================
       CREATE CLIENT
    =======================================================================================================================  */
        $scope.goToAddClient = function () {
            $scope.goToPage('clients/createClient');
        };

        $scope.initializeCreateClient = function () {
            $scope.newClient = null;
        };

        $scope.validateField = function (inputID, value, labelID, scrollToError) {
            let error;
            if (!value) {
                value = '';
            }
            if (inputID === 'clientNameInput') {
                error = $scope.validateStandardTextInput(inputID, value, true);
            } else if (inputID === 'clientContactMailInput') {
                error = $scope.validateEmailInput(inputID, value, false);
            } else if (inputID === 'clientSFTPUrlInput') {
                error = $scope.validateURLInput(inputID, value, true);
            } else if (inputID === 'clientSFTPPortInput') {
                error = $scope.validateNumberInput(inputID, value, 1, 65535, true);
            } else if (inputID === 'clientSFTPUsernameInput') {
                error = $scope.validateStandardTextInput(inputID, value, true);
            } else if (inputID === 'clientSFTPPasswordInput') {
                error = $scope.validatePasswordInput(inputID, value, 1, 0, false, false, true);
            } else if (inputID === 'clientTimezoneInput') {
                error = $scope.validateTimezoneInput(inputID, value, false);
            } else {
                error = $scope.validateStandardTextInput(inputID, value, false);
            }

            if (error) {
                if (scrollToError) {
                    $scope.scrollToElement(labelID);
                }
                $('#' + inputID).popover({
                    title: $translate.instant('error'),
                    content: error,
                    placement: 'right',
                    trigger: 'focus',
                });
                $('#' + inputID).popover('show');
                $scope.setFocus(inputID);
                document.getElementById(labelID).classList.add('status-red');
                document.getElementById(labelID).classList.remove('status-green');
            } else if (value.length <= 0) {
                document.getElementById(labelID).classList.remove('status-red');
                document.getElementById(labelID).classList.remove('status-green');
            } else {
                document.getElementById(labelID).classList.remove('status-red');
                document.getElementById(labelID).classList.add('status-green');
            }

            return error;
        };

        $scope.confirmCreation = function (client) {
            $scope.newClient = null;
            if (client) {
                const toValidate = [
                    {
                        inputID: 'clientNameInput',
                        value: client.name,
                        labelID: 'clientNameLabel',
                    },
                    {
                        inputID: 'clientFullNameInput',
                        value: client.fullName,
                        labelID: 'clientFullNameLabel',
                    },
                    {
                        inputID: 'clientAddressInput',
                        value: client.address,
                        labelID: 'clientAddressLabel',
                    },
                    {
                        inputID: 'clientPhoneInput',
                        value: client.phoneNumber,
                        labelID: 'clientPhoneLabel',
                    },
                    {
                        inputID: 'clientTimezoneInput',
                        value: client.timezone,
                        labelID: 'clientTimezoneLabel',
                    },
                ];

                if (client.sftpAccess) {
                    toValidate.push(
                        {
                            inputID: 'clientSFTPUrlInput',
                            value: client.sftpAccess.url,
                            labelID: 'clientSFTPUrlLabel',
                        },
                        {
                            inputID: 'clientSFTPPortInput',
                            value: client.sftpAccess.port,
                            labelID: 'clientSFTPPortLabel',
                        },
                        {
                            inputID: 'clientSFTPUsernameInput',
                            value: client.sftpAccess.username,
                            labelID: 'clientSFTPUsernameLabel',
                        },
                        {
                            inputID: 'clientSFTPPasswordInput',
                            value: client.sftpAccess.password,
                            labelID: 'clientSFTPPasswordLabel',
                        }
                    );
                } else {
                    $scope.validateField('clientSFTPUrlInput', '', 'clientSFTPUrlLabel', true);
                    return;
                }

                if (client.contact) {
                    toValidate.push(
                        {
                            inputID: 'clientContactFirstNameInput',
                            value: client.contact.firstName,
                            labelID: 'clientContactFirstNameLabel',
                        },
                        {
                            inputID: 'clientContactLastNameInput',
                            value: client.contact.lastName,
                            labelID: 'clientContactLastNameLabel',
                        },
                        {
                            inputID: 'clientContactTitleInput',
                            value: client.contact.title,
                            labelID: 'clientContactTitleLabel',
                        },
                        {
                            inputID: 'clientContactPhoneInput',
                            value: client.contact.phoneNumber,
                            labelID: 'clientContactPhoneLabel',
                        },
                        {
                            inputID: 'clientContactMailInput',
                            value: client.contact.email,
                            labelID: 'clientContactMailLabel',
                        }
                    );
                }

                for (const entryIndex in toValidate) {
                    const inputID = toValidate[entryIndex].inputID;
                    const value = toValidate[entryIndex].value;
                    const labelID = toValidate[entryIndex].labelID;
                    if ($scope.validateField(inputID, value, labelID, true)) {
                        // Error is returned
                        return;
                    }
                }

                $scope.scrollToTop();
                $scope.newClient = client;
            } else {
                $scope.validateField('clientNameInput', '', 'clientNameLabel', true);
            }
        };

        $scope.addClient = function (newClient) {
            $scope.requestAdminCreateClient(newClient).then(
                function (answer) {
                    if (answer.error) {
                        $scope.scrollToTop();
                        $scope.triggerCustomNotification(
                            'createClientNotificationError',
                            'createClientNotificationErrorMessage',
                            answer.error.message
                        );
                    } else {
                        $scope.goToPage('/clients');
                    }
                },
                function (error) {
                    $scope.scrollToTop();
                    $scope.triggerCustomNotification(
                        'createClientNotificationError',
                        'createClientNotificationErrorMessage',
                        error.responseJSON.error.message
                    );
                }
            );
        };

        /* =======================================================================================================================
       SHOW A CLIENT'S DETAILS
    =======================================================================================================================  */
        $scope.initializeClient = function () {
            $scope.loading = true;
            const clientID = $scope.getIdFromURL();

            $scope.showLinkOperatorModal = false;
            $scope.newOperator = {
                carrierId: '',
                internalId: '',
                clientCanManageAssignments: false,
            };
            $scope.linkOperatorSelect = {
                optionsOpen: false,
                searchValue: '',
                selectedOperator: null,
            };

            $scope.requestAdminGetClient(clientID).then(
                function (answer) {
                    $scope.client = answer;

                    if ($scope.client.lastImport != null) {
                        $scope.client._lastImportDate = $scope.makeDateRelative($scope.client.lastImport);
                    }
                    $scope.fetchCarriers(clientID);
                    $scope.fetchManagers(clientID);

                    $scope.directionsSource = $scope.client.settings.directionsSource;
                    $scope.guestPassengersEnabled = $scope.client.settings.guestPassengers;
                    $scope.plannedRouteChangesEnabled = $scope.client.settings.plannedRouteChanges;
                    $scope.formValidationRequiredForObserverEnabled = $scope.client.settings.formValidationRequiredForObserver;
                    $scope.emailInstitutionsWhenTripIsCancelledEnabled = $scope.client.settings.emailInstitutionsWhenTripIsCancelled;
                    $scope.tripNotStartedEmailNotificationEnabled = $scope.client.settings.tripNotStartedEmailNotification;
                    $scope.driverAppAllButtonEnabled = $scope.client.settings.driverAppAllButtonEnabled;
                    $scope.stopsRadiusEditingEnabled = $scope.client.settings.stopsRadiusEditing;
                    $scope.showWrongCardReadOnDashboardEnabled = $scope.client.settings.showWrongCardReadOnDashboard;
                },
                function (error) {
                    $scope.catchErrorDefault(error);
                }
            );

            $scope.angRequestAdminGetAvailableFormsForClient(clientID).then(
                function (answer) {
                    answer.data.availableForms.forEach((type) => {
                        const formType = Object.values(type).toString();

                        if (formType === 'photos') {
                            $scope.hasPhotosForm = true;
                        } else if (formType === 'disciplinary') {
                            $scope.hasDisciplinaryForm = true;
                        }
                    });
                },
                function (error) {
                    $rootScope.$broadcast('request-error-client-available-forms', error);
                }
            );
        };

        $scope.fetchCarriers = function (clientID) {
            $scope.requestAdminGetCarriersForClient(clientID).then(
                function (answer) {
                    $scope.carriersForClient = answer.carriers;
                    $scope.fetchInstitutions(clientID);
                },
                function (error) {
                    $scope.catchErrorDefault(error);
                }
            );
        };

        $scope.refreshCarriers = function (clientID) {
            $scope.requestAdminGetCarriersForClient(clientID).then(
                function (answer) {
                    $scope.carriersForClient = answer.carriers;
                    $scope.$apply();
                },
                function (error) {
                    $scope.catchErrorDefault(error);
                }
            );
        };

        /**
         * Fetch all managers of a specific client
         * @param {String} clientID
         */
        $scope.fetchManagers = function (clientID) {
            $scope.requestAdminGetManagersForClient(clientID).then(
                function (answer) {
                    $scope.allManagers = sortManagers(answer.managers);
                    $scope.$apply();
                },
                function (error) {
                    $scope.catchErrorDefault(error);
                }
            );
        };

        $scope.fetchInstitutions = function (clientID) {
            $scope.requestAdminGetInstitutionsForClient(clientID).then(
                function (answer) {
                    let defaultInstitution;
                    const institutions = answer.data.institutions;

                    // Store default institution if it exists
                    const index = institutions.findIndex(function (institution, i) {
                        return institution.internalId === 'default';
                    });

                    if (index !== -1) {
                        defaultInstitution = institutions.splice(index, 1)[0];
                    }

                    $scope.institutionsForClient = sortingTools.sortInstitutions(answer.data.institutions);

                    // Put default institution at start of the array if it exists
                    if (defaultInstitution != null) {
                        $scope.institutionsForClient.unshift(defaultInstitution);
                    }

                    $scope.loading = false;
                },
                function (error) {
                    $scope.catchErrorDefault(error);
                }
            );
        };

        /* =======================================================================================================================
       EDIT A CLIENT'S DETAILS
    =======================================================================================================================  */
        $scope.closeAllEditBoxes = function () {
            $scope.closeEditClientCoordinates();
            $scope.closeEditClientContact();
            $scope.closeEditClientSFTP();
        };

        /* Coordinates ========================================================================= */
        $scope.openEditClientCoordinates = function () {
            setCoordinates();
            $scope.closeAllEditBoxes();
            $scope.editingClientCoordinates = true;
            $timeout(function () {
                $scope.setFocus('clientCoordinatesPhoneInput');
            });
        };

        $scope.closeEditClientCoordinates = function () {
            $scope.editingClientCoordinates = false;
            setCoordinates();
            $scope.resetInputState('clientCoordinatesAddressInput');
            $scope.resetInputState('clientCoordinatesPhoneInput');
            $scope.resetInputState('clientCoordinatesLocaleIdentifier');
        };

        function setCoordinates() {
            $scope.coordinates = {
                address: $scope.client.address,
                phone: $scope.client.phoneNumber,
                localeIdentifier: $scope.client.localeIdentifier,
            };
        }

        $scope.confirmCoordinatesChanges = function (coordinates) {
            const address = coordinates.address;
            const phone = coordinates.phone;
            const localeIdentifier = coordinates.localeIdentifier;

            const addressError = $scope.validateStandardTextInput('clientCoordinatesAddressInput', address, false);
            const phoneError = $scope.validateStandardTextInput('clientCoordinatesPhoneInput', phone, false);
            const localeIdentifierError = $scope.validateStandardTextInput('clientCoordinatesLocaleIdentifier', localeIdentifier, false);

            if (!addressError && !phoneError && !localeIdentifierError) {
                updateClientCoordinates($scope.client.id, phone, address, localeIdentifier);
            }
        };

        function updateClientCoordinates(clientId, phone, address, localeIdentifier) {
            const data = {
                address: address,
                phoneNumber: phone,
                localeIdentifier,
            };

            $scope.requestAdminUpdateClient(clientId, data).then(
                function (answer) {
                    $scope.refreshPage();
                },
                function (error) {
                    // Trigger notifications
                }
            );
        }

        /* Contact ============================================================================= */
        $scope.openEditClientContact = function () {
            setContact();
            $scope.closeAllEditBoxes();
            $scope.editingClientContact = true;
            $timeout(function () {
                $scope.setFocus('clientContactFirstName');
            });
        };

        $scope.closeEditClientContact = function () {
            $scope.editingClientContact = false;
            setContact();
            $scope.resetInputState('clientContactFirstName');
            $scope.resetInputState('clientContactLastName');
            $scope.resetInputState('clientContactTitle');
            $scope.resetInputState('clientContactEmail');
            $scope.resetInputState('clientContactPhoneNumber');
            if ($('#clientContactEmail').popover()) {
                $('#clientContactEmail').popover('dispose');
            }
        };

        /**
         * Sets contact information based on client data.
         */
        function setContact() {
            $scope.contact = {
                firstName: $scope.client.contact.firstName,
                lastName: $scope.client.contact.lastName,
                title: $scope.client.contact.title,
                email: $scope.client.contact.email,
                phone: $scope.client.contact.phoneNumber,
            };
        }

        $scope.confirmContactChanges = function (contact) {
            const firstName = contact.firstName;
            const lastName = contact.lastName;
            const title = contact.title;
            const email = contact.email;
            const phoneNumber = contact.phone;

            const firstNameError = $scope.validateStandardTextInput('clientContactFirstName', firstName, false);
            const lastNameError = $scope.validateStandardTextInput('clientContactLastName', lastName, false);
            const titleError = $scope.validateStandardTextInput('clientContactTitle', title, false);
            const emailError = $scope.validateEmailInput('clientContactEmail', email, true);
            const phoneNumberError = $scope.validateStandardTextInput('clientContactPhoneNumber', phoneNumber, false);

            let error = false;
            if (firstNameError) {
                error = true;
            }
            if (lastNameError) {
                error = true;
            }
            if (titleError) {
                error = true;
            }
            if (emailError) {
                $('#clientContactEmail').popover({
                    title: $translate.instant('error'),
                    content: emailError,
                    placement: 'top',
                    trigger: 'focus',
                });
                $('#clientContactEmail').popover('show');
                error = true;
            }
            if (phoneNumberError) {
                error = true;
            }

            if (!error) {
                updateClientContact($scope.client.id, firstName, lastName, title, email, phoneNumber);
                if ($('#clientContactEmail').popover()) {
                    $('#clientContactEmail').popover('dispose');
                }
            }
        };

        /**
         * Updates client contact information.
         *
         * @param {string} clientId - The ID of the client.
         * @param {string} firstName - The first name of the client contact.
         * @param {string} lastName - The last name of the client contact.
         * @param {string} title - The title of the client contact.
         * @param {string} email - The email address of the client contact.
         * @param {string} phone - The phone number of the client contact.
         */
        function updateClientContact(clientId, firstName, lastName, title, email, phone) {
            const data = {
                contact: {
                    firstName: firstName,
                    lastName: lastName,
                    title: title,
                    phoneNumber: phone,
                    email: email,
                },
            };

            $scope.requestAdminUpdateClient(clientId, data).then(
                function (answer) {
                    $scope.refreshPage();
                },
                function (error) {
                    // Trigger notifications
                }
            );
        }

        /* SFTP ================================================================================ */
        $scope.openEditClientSFTP = function () {
            setSFTP();
            $scope.closeAllEditBoxes();
            $scope.editingClientSFTP = true;
            $timeout(function () {
                $scope.setFocus('clientSFTPUsernameInput');
            });
        };

        $scope.closeEditClientSFTP = function () {
            $scope.editingClientSFTP = false;
            setSFTP();
            $scope.resetInputState('clientSFTPUsernameInput');
            $scope.resetInputState('clientSFTPUrlInput');
            $scope.resetInputState('clientSFTPPortInput');
            if ($('#clientSFTPUsernameInput').popover()) {
                $('#clientSFTPUsernameInput').popover('dispose');
            }
            if ($('#clientSFTPPortInput').popover()) {
                $('#clientSFTPPortInput').popover('dispose');
            }
            if ($('#clientSFTPUrlInput').popover()) {
                $('#clientSFTPUrlInput').popover('dispose');
            }
        };

        function setSFTP() {
            $scope.SFTP = {
                url: $scope.client.sftpAccess.url,
                port: $scope.client.sftpAccess.port,
                username: $scope.client.sftpAccess.username,
            };
        }

        $scope.confirmSFTPChanges = function (sftp) {
            if ($('#clientSFTPUsernameInput').popover()) {
                $('#clientSFTPUsernameInput').popover('dispose');
            }
            if ($('#clientSFTPPortInput').popover()) {
                $('#clientSFTPPortInput').popover('dispose');
            }
            if ($('#clientSFTPUrlInput').popover()) {
                $('#clientSFTPUrlInput').popover('dispose');
            }
            const url = sftp.url;
            const port = sftp.port;
            const username = sftp.username;

            const urlError = $scope.validateURLInput('clientSFTPUrlInput', url, true);
            const portError = $scope.validateNumberInput('clientSFTPPortInput', port, 1, 65535, true);
            const usernameError = $scope.validateStandardTextInput('clientSFTPUsernameInput', username, true);

            let error = false;
            if (urlError) {
                error = true;
                $('#clientSFTPUrlInput').popover({
                    title: $translate.instant('error'),
                    content: urlError,
                    placement: 'top',
                    trigger: 'focus',
                });
                $scope.setFocus('clientSFTPUrlInput');
            }
            if (portError) {
                error = true;
                $('#clientSFTPPortInput').popover({
                    title: $translate.instant('error'),
                    content: portError,
                    placement: 'top',
                    trigger: 'focus',
                });
                $scope.setFocus('clientSFTPPortInput');
            }
            if (usernameError) {
                error = true;
                $('#clientSFTPUsernameInput').popover({
                    title: $translate.instant('error'),
                    content: usernameError,
                    placement: 'top',
                    trigger: 'focus',
                });
                $scope.setFocus('clientSFTPUsernameInput');
            }

            if (!error) {
                updateSFTPContact($scope.client.id, url, port, username);
                if ($('#clientSFTPUsernameInput').popover()) {
                    $('#clientSFTPUsernameInput').popover('dispose');
                }
                if ($('#clientSFTPPortInput').popover()) {
                    $('#clientSFTPPortInput').popover('dispose');
                }
                if ($('#clientSFTPUrlInput').popover()) {
                    $('#clientSFTPUrlInput').popover('dispose');
                }
            }
        };

        function updateSFTPContact(clientId, url, port, username) {
            const data = {
                sftpAccess: {
                    url: url,
                    port: port,
                    username: username,
                },
            };

            $scope.requestAdminUpdateClient(clientId, data).then(
                function (answer) {
                    $scope.refreshPage();
                },
                function (error) {
                    // Trigger notifications
                }
            );
        }

        /* Managers ================================================================================ */

        /**
         * Sort managers according to their first name and if they are deactivated or not
         * @param {Object[]} managers
         * @return {Object[]} array of sorted managers
         */
        const sortManagers = (managers) => {
            // Sort according to first name
            managers.sort((a, b) => a.firstName.localeCompare(b.firstName));
            return managers;
        };

        /**
         * Displays a modal to confirm the deactivation of the user and make the request
         * Upon success, page is refreshed
         * @param {Object} user
         */
        $scope.deactivateUser = (user) => {
            $scope.changeConfirmationModalEmail = user.email;

            $scope.showChangeConfirmationModal = true;
            $scope.confirmationModalAction = async () => {
                $scope.showChangeConfirmationModal = false;

                $scope.requestDeactivateUser(user.id).then(
                    () => {
                        // Redirect to the same page
                        $scope.refreshPage();
                    },
                    (error) => {
                        if (error.message) {
                            $scope.triggerCustomNotification('client-genericError', 'clientGenericErrorMessage', $translate.instant(error.message));
                        } else {
                            $scope.triggerCustomNotification(
                                'client-genericError',
                                'clientGenericErrorMessage',
                                $translate.instant('unknownErrorMessage')
                            );
                        }
                    }
                );
            };
        };

        /**
         * Displays a modal to confirm the use of a client tool
         * @param {string} action
         */
        $scope.openToolsConfirmationModal = (action) => {
            $scope.showToolsConfirmationModal = true;
            switch (action) {
                case 'updateData':
                    $scope.toolsConfirmationMessage = $translate.instant('confirmUpdateData');
                    break;
                case 'scheduleJobs':
                    $scope.toolsConfirmationMessage = $translate.instant('confirmScheduleJobs');
                    break;
                case 'userAPI':
                    $scope.toolsConfirmationMessage = $translate.instant('confirmUserAPI');
                    break;
            }

            $scope.confirmationToolsModalAction = async () => {
                $scope.showToolsConfirmationModal = false;

                switch (action) {
                    case 'updateData':
                        $scope.FTPFetch();
                        break;
                    case 'scheduleJobs':
                        $scope.ScheduleJobs();
                        break;
                    case 'userAPI':
                        $scope.ShowUserAPI();
                        break;
                }
            };
        };

        /* Carriers ================================================================================ */

        $scope.updateCarrierManagingSettings = () => {
            $scope.newOperator.clientCanManageAssignments = !$scope.newOperator.clientCanManageAssignments;
        };

        function sortingCarriers(carriers) {
            return carriers.sort((a, b) => a.name.localeCompare(b.name));
        }

        $scope.fetchAllCarriers = function () {
            $scope.requestAdminGetAllCarriers().then(
                function (answer) {
                    $scope.allCarriers = sortingCarriers(answer.carriers);
                    $scope.$apply();
                },
                function (error) {
                    $scope.triggerCustomNotification('client-carriersNotificationError', 'carriersNotificationErrorMessage', error.message);
                }
            );
        };

        /**
         * Add carrier to client
         */
        function addCarrier() {
            $scope.newOperator.carrierId = $scope.linkOperatorSelect.selectedOperator.id;
            $scope.requestAdminClientAddCarrier($scope.client.id, $scope.newOperator).then(
                function (answer) {
                    if (answer.error) {
                        $scope.linkOperatorLoading = false;
                        $scope.internalIdNotUniqueError = true;
                        $timeout(() => {
                            $("input[name='linkOperatorInternalId']").addClass('ng-invalid ng-touched');
                        });

                        $scope.$apply();
                    } else {
                        $scope.refreshCarriers($scope.getIdFromURL());
                        $scope.resetLinkOperatorModalVars();
                        $scope.showLinkOperatorModal = false;
                        $scope.$apply();
                    }
                },
                function (error) {
                    // Trigger notifications
                }
            );
        }

        /* LINK CARRIER ================================================================================ */
        /**
         * Form submit function
         * Checks if the modal form is valid and then calls the addCarrier function if so
         * @param {Object} form the submitted user form
         */
        $scope.submitLinkOperatorForm = function (form) {
            if (form.$valid) {
                $scope.linkOperatorLoading = true;
                addCarrier();
                $scope.linkOperatorLoading = false;
            }
        };

        /**
         * In link operator modal, when the user changes the internal id input, remove the Internal ID must be unique error message
         */
        $scope.onLinkOperatorInternalIdChange = () => {
            $scope.internalIdNotUniqueError = false;
            if ($scope.newOperator.internalId) {
                $timeout(() => {
                    $("input[name='linkOperatorInternalId']").removeClass('ng-invalid ng-touched');
                });
            }
        };

        $scope.resetLinkOperatorModalVars = () => {
            $scope.newOperator = {
                carrierId: '',
                internalId: '',
                clientCanManageAssignments: false,
            };
            $scope.internalIdNotUniqueError = false;
        };

        /* APP PARENT INFOS ================================================================================ */
        $scope.openEditAppParent = function () {
            setInfosAppParent();
            $scope.closeAllEditBoxes();
            $scope.editingInfosAppParent = true;
            $timeout(function () {
                $scope.setFocus('infosAppParentTextToDisplayTextArea');
            });
        };

        $scope.closeEditInfosAppParent = function () {
            $scope.editingInfosAppParent = false;
            setInfosAppParent();
            $scope.resetInputState('infosAppParentTextToDisplayTextArea');
            if ($('#infosAppParentTextToDisplayTextArea').popover()) {
                $('#infosAppParentTextToDisplayTextArea').popover('dispose');
            }

            if ($('#infosAppParentPhoneInput').popover()) {
                $('#infosAppParentPhoneInput').popover('dispose');
            }
        };

        /**
         * Sets the guardian's contact information if it exists.
         */
        function setInfosAppParent() {
            if ($scope.client.guardiansContact != null) {
                $scope.guardiansContact = {
                    information: $scope.client.guardiansContact.information,
                    phoneNumber: $scope.client.guardiansContact.phoneNumber,
                    webpage: {
                        title: $scope.client.guardiansContact.webpage.title,
                        url: $scope.client.guardiansContact.webpage.url,
                    },
                };
            }
        }

        $scope.confirmInfosAppParentChanges = function (guardiansContact) {
            if (guardiansContact != null) {
                const information = guardiansContact.information;
                const phoneNumber = guardiansContact.phoneNumber;
                const webpageTitle = guardiansContact.webpage?.title;
                const webpageUrl = guardiansContact.webpage?.url;

                const fields = [
                    {
                        id: 'infosAppParentTextToDisplayTextArea',
                        value: information,
                        error: $scope.validateStandardTextInput('infosAppParentTextToDisplayTextArea', information, false),
                    },
                    {
                        id: 'infosAppParentPhoneInput',
                        value: phoneNumber,
                        error: $scope.validateStandardTextInput('infosAppParentPhoneInput', phoneNumber, false),
                    },
                    {
                        id: 'infosAppParentTitleInput',
                        value: webpageTitle,
                        error: $scope.validateStandardTextInput('infosAppParentTitleInput', webpageTitle, false),
                    },
                    {
                        id: 'infosAppParentUrlInput',
                        value: webpageUrl,
                        error: $scope.validateStandardTextInput('infosAppParentUrlInput', webpageUrl, false),
                    },
                ];

                let error = false;
                fields.forEach((field) => {
                    if (field.error) {
                        error = true;
                        $(`#${field.id}`).popover({
                            title: $translate.instant('error'),
                            content: field.error,
                            placement: 'top',
                            trigger: 'focus',
                        });
                        $scope.setFocus(field.id);
                    }
                });

                if (!error) {
                    updateAppParentInfos($scope.client.id, information, phoneNumber, webpageTitle, webpageUrl);
                    fields.forEach((field) => {
                        if ($(`#${field.id}`).popover()) {
                            $(`#${field.id}`).popover('dispose');
                        }
                    });
                }
            } else {
                $scope.closeEditInfosAppParent();
            }
        };

        function updateAppParentInfos(clientId, information, phoneNumber, webpageTitle, webpageUrl) {
            const data = {
                guardiansContact: {
                    information: information,
                    phoneNumber: phoneNumber,
                    webpage: {
                        title: webpageTitle,
                        url: webpageUrl,
                    },
                },
            };

            $scope.requestAdminUpdateClient(clientId, data).then(
                function (answer) {
                    $scope.refreshPage();
                },
                function (error) {
                    // Trigger notifications
                }
            );
        }

        /* Institutions  ================================================================================ */
        $scope.goToShowInstitution = function (institution) {
            const clientId = $scope.client.id;
            const path = `/clients/institution?clientId=${clientId}&institutionId=${institution.id}`;

            $location.url(path);
        };

        $scope.goBackToClient = function () {
            $scope.showAPI = false;
            $scope.showManagerCreation = false;
            $scope.scrollToTop();
        };

        /* Settings =================================================================================== */

        /**
         * Update available forms for client
         * @param {'photos' | 'disciplinary'} formType
         */
        $scope.toggleFormOption = (formType) => {
            if (formType === 'photos') {
                $scope.hasPhotosForm = !$scope.hasPhotosForm;
            } else {
                $scope.hasDisciplinaryForm = !$scope.hasDisciplinaryForm;
            }
            $scope.updateAvailableForms();
        };

        /**
         * Update available forms for client
         */
        $scope.updateAvailableForms = () => {
            const availableForms = [];

            if ($scope.hasPhotosForm) {
                availableForms.push('photos');
            }
            if ($scope.hasDisciplinaryForm) {
                availableForms.push('disciplinary');
            }

            const data = {
                availableForms: availableForms,
            };

            $scope.angRequestAdminAvailableFormsUpdateForClient($scope.client.id, data).catch(function (error) {
                $rootScope.$broadcast('request-error-client-available-forms', error);
            });
        };

        /**
         * Toggle a client setting
         * If the setting is ON, it will be turned OFF and vice versa
         * @param {string} setting
         */
        const toggleSetting = (setting) => {
            $scope[setting] = !$scope[setting];
        };

        /**
         * Update client setting
         * @param {string} setting
         * @param {string} [value] - when the setting is not a boolean
         */
        $scope.updateClientSetting = (setting, value = null) => {
            const data = {};
            let broadcastError = '';

            switch (setting) {
                case 'guestPassengers':
                    toggleSetting('guestPassengersEnabled');
                    data.guestPassengers = $scope.guestPassengersEnabled;
                    broadcastError = 'request-error-client-guest-passengers';
                    break;
                case 'directionsSource':
                    $scope.directionsSource = value;
                    data.directionsSource = $scope.directionsSource;
                    broadcastError = 'request-error-client-directions-source';
                    break;
                case 'formValidationRequiredForObserver':
                    toggleSetting('formValidationRequiredForObserverEnabled');
                    data.formValidationRequiredForObserver = $scope.formValidationRequiredForObserverEnabled;
                    broadcastError = 'request-error-client-schools';
                    break;
                case 'emailInstitutionsWhenTripIsCancelled':
                    toggleSetting('emailInstitutionsWhenTripIsCancelledEnabled');
                    data.emailInstitutionsWhenTripIsCancelled = $scope.emailInstitutionsWhenTripIsCancelledEnabled;
                    broadcastError = 'request-error-client-schools';
                    break;
                case 'plannedRouteChanges':
                    toggleSetting('plannedRouteChangesEnabled');
                    data.plannedRouteChanges = $scope.plannedRouteChangesEnabled;
                    broadcastError = 'request-error-client-planned-route-changes';
                    break;
                case 'tripNotStartedEmailNotification':
                    toggleSetting('tripNotStartedEmailNotificationEnabled');
                    data.tripNotStartedEmailNotification = $scope.tripNotStartedEmailNotificationEnabled;
                    broadcastError = 'request-error-client-trip-not-started-email-notification';
                    break;
                case 'driverAppAllButtonEnabled':
                    toggleSetting('driverAppAllButtonEnabled');
                    data.driverAppAllButtonEnabled = $scope.driverAppAllButtonEnabled;
                    broadcastError = 'request-error-client-driver-app-all-button';
                    break;
                case 'stopsRadiusEditing':
                    toggleSetting('stopsRadiusEditingEnabled');
                    data.stopsRadiusEditing = $scope.stopsRadiusEditingEnabled;
                    broadcastError = 'request-error-client-stop-radius-change-button';
                    break;
                case 'showWrongCardReadOnDashboard':
                    toggleSetting('showWrongCardReadOnDashboardEnabled');
                    data.showWrongCardReadOnDashboard = $scope.showWrongCardReadOnDashboardEnabled;
                    broadcastError = 'request-error-client-wrong-card-read-on-dashboard';
                    break;
                default:
                    throw new Error(`Unknown client setting: ${setting}`);
            }

            $scope.requestAdminClientSettingsUpdate($scope.client.id, data).then(
                function () {
                    // Success
                },
                function (error) {
                    $rootScope.$broadcast(broadcastError, error);
                }
            );
        };

        /* =======================================================================================================================
       TOOLS
    =======================================================================================================================  */
        $scope.FTPFetch = function () {
            $scope.loading = true;
            const clientId = $scope.client.id;
            $scope.requestAdminFTPFetch(clientId).then(
                function (answer) {
                    $scope.loading = false;
                    if (answer.error) {
                        $scope.triggerCustomNotification('client-toolsNotificationError', 'toolsNotificationErrorMessage', answer.message);
                    } else {
                        $scope.triggerCustomNotification('client-toolsNotification', 'toolsNotificationMessage', answer.message);
                    }
                    $scope.$apply();
                },
                function (error) {
                    $scope.loading = false;
                    $scope.triggerCustomNotification('client-toolsNotificationError', 'toolsNotificationErrorMessage', error.message);
                    $scope.$apply();
                }
            );
        };

        $scope.ScheduleJobs = function () {
            $scope.loading = true;
            const clientId = $scope.client.id;
            $scope.requestAdminScheduleJobs(clientId).then(
                function (answer) {
                    $scope.loading = false;
                    if (answer.error) {
                        $scope.triggerCustomNotification('client-toolsNotificationError', 'toolsNotificationErrorMessage', answer.message);
                    } else {
                        $scope.triggerCustomNotification('client-toolsNotification', 'toolsNotificationMessage', answer.message);
                    }
                    $scope.$apply();
                },
                function (error) {
                    $scope.loading = false;
                    $scope.triggerCustomNotification('client-toolsNotificationError', 'toolsNotificationErrorMessage', error.message);
                    $scope.$apply();
                }
            );
        };

        /* API ================================================================================= */
        $scope.ShowUserAPI = function () {
            $scope.showAPI = true;
            $scope.publicKeyError = null;
            $scope.privateKeyError = null;
        };

        $scope.saveAPIKeys = function (API) {
            if (!$scope.publicKeyError && !$scope.privateKeyError && API?.publicKey && API?.privateKey) {
                $scope.userAPILoading = true;
                $scope.requestAdminAddClientAPI(API.publicKey, API.privateKey, $scope.client.id).then(
                    function (answer) {
                        $scope.userAPILoading = false;
                        $scope.goBackToClient();
                        const successMessage =
                            $translate.instant('clientToolsAPIKeysSaved') + ' ' + $translate.instant('publicKey') + ' : ' + answer.publicKey;
                        $scope.triggerCustomNotification('client-toolsNotification', 'toolsNotificationMessage', successMessage);
                        $scope.$apply();
                    },
                    function (error) {
                        $scope.userAPILoading = false;
                        $scope.goBackToClient();
                        $scope.triggerCustomNotification(
                            'client-toolsNotificationError',
                            'toolsNotificationErrorMessage',
                            error.responseJSON.error.message
                        );
                        $scope.$apply();
                    }
                );
            }
        };

        /* =======================================================================================================================
       ADD MANAGER
	=======================================================================================================================  */
        $scope.GoToAddManager = function () {
            $scope.showManagerCreation = true;
            $scope.newManager = {
                gender: 'X',
                localeIdentifier: $scope.client.localeIdentifier,
            };
        };

        $scope.createManager = function (newManager) {
            $scope.managerLoading = true;
            const data = {
                email: newManager.email,
                firstName: newManager.firstName,
                lastName: newManager.lastName,
            };

            if (newManager.gender && newManager.gender.length >= 1) {
                data.gender = newManager.gender;
            }

            if (newManager.localeIdentifier && newManager.localeIdentifier.length >= 1) {
                data.localeIdentifier = newManager.localeIdentifier;
            }

            $scope.requestCreateManagerForClient($scope.client.id, data).then(
                function (answer) {
                    $scope.managerLoading = false;
                    $scope.refreshPage();
                },
                function (error) {
                    $scope.managerLoading = false;
                    const message = error.responseJSON?.error?.message ?? 'unknownErrorMessage';
                    $scope.triggerCustomNotification('client-genericError', 'clientGenericErrorMessage', $translate.instant(message));
                    $scope.$apply();
                }
            );
        };

        /* =======================================================================================================================
	   EXPORT CSV
	=======================================================================================================================  */

        /**
         * Get the data needed to generate the client's users (agents/obervers) list CSV
         * @return {Promise}
         */
        const getCSVExportAgentsOberversData = () => {
            const institutionsRequestPromises = [];

            // Managers data treatement
            // Get managers of default institution
            const index = $scope.institutionsForClient.findIndex((institution) => institution.internalId === 'default');
            const defaultInstitution = $scope.institutionsForClient[index];
            const defaultDataObj = [defaultInstitution.name, defaultInstitution.id];

            const clientID = $scope.getIdFromURL();
            const promiseManagers = $scope.requestAdminGetManagersForClient(clientID).then(function (data) {
                const managersData = [];
                Object.keys(data.managers).forEach(function (key) {
                    const manager = data.managers[key];
                    const managerObj = angular.copy(defaultDataObj);
                    managerObj.unshift($translate.instant('manager'));
                    managerObj.unshift(manager.lastName);
                    managerObj.unshift(manager.firstName);
                    managerObj.unshift(manager.email);
                    managerObj.unshift(manager.id);
                    managersData.push(managerObj);
                });
                return managersData;
            });
            institutionsRequestPromises.push(promiseManagers);

            Object.keys($scope.institutionsForClient).forEach(function (key) {
                const institution = $scope.institutionsForClient[key];
                const dataObj = [institution.name, institution.id];

                // Agents data treatement
                const promiseAgents = $scope.requestAdminGetAgentsForInstitution(institution.id).then(function (data) {
                    const agentsData = [];
                    Object.keys(data.agents).forEach(function (key) {
                        const agent = data.agents[key];

                        const agentObj = angular.copy(dataObj);
                        agentObj.unshift($translate.instant('agent'));
                        agentObj.unshift(agent.lastName);
                        agentObj.unshift(agent.firstName);
                        agentObj.unshift(agent.email);
                        agentObj.unshift(agent.id);
                        agentsData.push(agentObj);
                    });
                    return agentsData;
                });
                institutionsRequestPromises.push(promiseAgents);

                // Observers data treatement
                const promiseObservers = $scope.requestAdminGetObserversForInstitution(institution.id).then(function (data) {
                    const observersData = [];
                    Object.keys(data.observers).forEach(function (key) {
                        const oberver = data.observers[key];

                        const observerObj = angular.copy(dataObj);
                        observerObj.unshift($translate.instant('observer'));
                        observerObj.unshift(oberver.lastName);
                        observerObj.unshift(oberver.firstName);
                        observerObj.unshift(oberver.email);
                        observerObj.unshift(oberver.id);
                        observersData.push(observerObj);
                    });
                    return observersData;
                });
                institutionsRequestPromises.push(promiseObservers);
            });

            // Returns list of promises
            return $q.all(institutionsRequestPromises);
        };

        /**
         * Generate and download client's agent list in CSV
         */
        $scope.exportUserListCSV = () => {
            getCSVExportAgentsOberversData().then((users) => {
                const headers = ['userId', 'email', 'firstName', 'lastName', 'role', 'institutionName', 'institutionId'];

                const institutionIdIndex = headers.indexOf('institutionId');
                const csvUsers = [...users.flat()].filter((user) => user[institutionIdIndex]);
                const csv = Papa.unparse(
                    {
                        fields: headers,
                        data: csvUsers,
                    },
                    {
                        header: true,
                        quotes: true,
                        encoding: 'UTF-8',
                    }
                );

                $scope.downloadCSV(csv, 'users.csv');
            });
        };

        /**
         * Convert boolean value to a localized yes or no
         * @param {Boolean} boolean
         * @return {string} the localized yes or no
         */
        const convertBooleanToHumanReadable = (boolean) => {
            return boolean ? $translate.instant('yes') : $translate.instant('no');
        };

        /**
         * Generate and download a list of clients in CSV
         */
        $scope.generateCSVofClientsForAdmin = async () => {
            try {
                $scope.csvIsLoading = true;

                const headers = [
                    $translate.instant('clientsCSVheader_clientid'),
                    $translate.instant('clientsCSVheader_client'),
                    $translate.instant('clientsCSVheader_manager'),
                    $translate.instant('clientsCSVheader_institutions'),
                    $translate.instant('clientsCSVheader_carriers'),
                    $translate.instant('clientsCSVheader_guestPassengers'),
                    $translate.instant('clientsCSVheader_photosForm'),
                    $translate.instant('clientsCSVheader_disciplinaryForm'),
                    $translate.instant('clientsCSVheader_plannedRunChanges'),
                    $translate.instant('clientsCSVheader_emailToCarrier'),
                    $translate.instant('clientsCSVheader_directions'),
                    $translate.instant('clientsCSVheader_wrongCardReadOnDashboard'),
                    $translate.instant('clientsCSVheader_disciplinaryFormValidation'),
                    $translate.instant('clientsCSVheader_emailToInstitutions'),
                    $translate.instant('clientsCSVheader_numberOfVehicles'),
                    $translate.instant('clientsCSVheader_numberOfRoutes'),
                    $translate.instant('clientsCSVheader_todayTrips'),
                    $translate.instant('clientsCSVheader_tomorrowsTrips'),
                    $translate.instant('clientsCSVheader_tripsIn2Days'),
                    $translate.instant('clientsCSVheader_tripsIn3Days'),
                    $translate.instant('clientsCSVheader_tripsIn4Days'),
                    $translate.instant('clientsCSVheader_withAttendance'),
                    $translate.instant('clientsCSVheader_withParents'),
                    $translate.instant('clientsCSVheader_withAlerts'),
                ];

                const answer = await $scope.requestAdminGetAllClients(true, true);
                let clients = sortClients(answer.data.clients, 'name', true);

                // If search or favourites filter is active, only include the displayed clients
                const isFilterActive = $scope.filterOptions.showOnlyFavourites || $scope.searchValue.value.length > 0;

                if (isFilterActive) {
                    clients = clients.filter((client) => $scope.clients.some((displayedClient) => displayedClient.id === client.id));
                }

                const csvClients = await Promise.all(
                    clients.map(async (client) => {
                        const [details, carriers, institutions, managers, forms] = await Promise.all([
                            $scope.requestAdminGetClient(client.id),
                            $scope.requestAdminGetCarriersForClient(client.id),
                            $scope.requestAdminGetInstitutionsForClient(client.id),
                            $scope.requestAdminGetManagersForClient(client.id),
                            $scope.angRequestAdminGetAvailableFormsForClient(client.id),
                        ]);

                        const tripCount = calculateTripCountForNext4Days(client);

                        const csvClient = [
                            client.id,
                            client.name,
                            managers.managers
                                .filter((manager) => manager.isActive)
                                .map((manager) => manager.email)
                                .join(' | '),
                            institutions.data.institutions.length,
                            carriers.carriers.length,
                            convertBooleanToHumanReadable(details.settings.guestPassengers),
                            convertBooleanToHumanReadable(forms.data.availableForms.map((form) => form.formType).includes('photos')),
                            convertBooleanToHumanReadable(forms.data.availableForms.map((form) => form.formType).includes('disciplinary')),
                            convertBooleanToHumanReadable(details.settings.plannedRouteChanges),
                            convertBooleanToHumanReadable(details.settings.tripNotStartedEmailNotification),
                            details.settings.directionsSource,
                            details.settings.showWrongCardReadOnDashboard,
                            details.settings.formValidationRequiredForObserver,
                            details.settings.emailInstitutionsWhenTripIsCancelled,
                            client.busCount,
                            client.routeCount,
                            tripCount.today,
                            tripCount.tomorrow,
                            tripCount.in2Days,
                            tripCount.in3Days,
                            tripCount.in4Days,
                            client.routes.preferencesSummary.studentsBoardingRegistrations,
                            client.routes.preferencesSummary.notifications,
                            client.routes.preferencesSummary.automaticDelayNotifications,
                        ];

                        return csvClient;
                    })
                );

                const csv = Papa.unparse(
                    {
                        fields: headers,
                        data: csvClients,
                    },
                    {
                        header: true,
                        quotes: true,
                    }
                );

                const filename = moment().format('YYYY-MM-DD') + ' mTransport clients.csv';

                $scope.downloadCSV(csv, filename);
            } catch (error) {
                $scope.catchErrorDefault(error);
            } finally {
                $scope.csvIsLoading = false;
            }
        };

        /* =======================================================================================================================
       LAYOUT
    =======================================================================================================================  */
        $(window).on('resize', function (e) {
            $scope.$apply();
        });
    },
]);
