/**
 * Check if time is in HH:MM format and is valid
 * @param {String} time
 * @return {Boolean}
 */
const validateTimeHHMM = function (time) {
    const timeFormatHHMM = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
    return timeFormatHHMM.test(time);
};

module.exports = { validateTimeHHMM };
